//for  cache issue

// import React, { useEffect, useState } from "react";
// import LoadMoreBtn from "../../loaders/LoadMoreBtn";
// import { STORY_FIELDS } from "../../../constants";
// import SubscribeBox from "../../templates/SubscribeBox";
// import { RightImageCard } from "../../skeleton/MobileSkeleton";
// import axios from "axios";
// import { set } from "lodash";

// const SectionPage = (props) => {
//   const [stories, setStories] = useState(props.data.collection.items);
//   const [isLoading, setIsLoading] = useState(true);
//   if (Object.keys(props.data.collection).length === 0) {
//     setStories(props.data.section.stories);
//     let getSectionStories = async (sectionId, offset) => {
//       try {
//         let url = `/api/v1/stories?section-id=${sectionId}&offset=${offset}&limit=20&fields=${STORY_FIELDS}`;
//         const res = await fetch(url, {
//           method: "GET",
//           headers: {
//             "Content-Type": "application/json",
//           },
//         });
//         const data = await res.json();
//         return {
//           items: data.stories,
//         };
//       } catch (error) {
//         console.error(`error.message`, error.message);
//         return { items: [] };
//       }
//     };
//   } else {
//     setStories(props.data.collection.items);
//     const getSectionStories = async (sectionSlug, offset) => {
//       if (sectionSlug === "news-brief") {
//         sectionSlug = "news-headlines";
//       }
//       try {
//         let url = `/api/v1/collections/${sectionSlug}?offset=${offset}&limit=20`;
//         const res = await axios.get(url, {
//           headers: {
//             "Content-Type": "application/json",
//           },
//         });

//         return {
//           items: res.data.items,
//         };
//       } catch (error) {
//         console.error(`Error: ${error.message}`);
//         return { items: [] };
//       }
//     };
//   }

//   // useEffect(() => {
//   //   async function getSectionStoriesEffect() {
//   //     setIsLoading(true);
//   //     let sectionId = props?.data?.collection?.slug;
//   //     console.log("sectionId", sectionId);
//   //     let storiesdata = await getSectionStories(sectionId, 0);
//   //     let storiesRes = storiesdata.items;

//   //     setStories(storiesRes);
//   //     setIsLoading(false);
//   //   }

//   //   getSectionStoriesEffect();
//   // }, [props.data.collection?.slug]);

//   // if (isLoading) {
//   //   return (
//   //     <div className="mb-2 pb-3">
//   //       <RightImageCard />
//   //     </div>
//   //   );
//   // }

//   return (
//     <div>
//       <div className="mobile:hidden">
//         <div className="mx-auto max-w-[950px]">
//           <h1 className={`gap-6 text-[60px] text-primary`}>{props.data.collection.name}</h1>

//           <div style={{ display: "flex", gap: "10px", marginTop: "10px" }}>
//             <div className="w-[67%]">
//               <LoadMoreBtn
//                 stories={stories}
//                 id={props.data.collection.slug}
//                 getMoreStoriesFunction={getSectionStories}
//                 isSection={true}
//                 isBookmark={false}
//               />
//             </div>
//             <div className="w-[33%]">
//               <SubscribeBox />
//             </div>
//           </div>
//         </div>
//       </div>
//       {/* Mobile View */}
//       <div className="hidden flex-col gap-8 mobile:flex">
//         <div className="mr-[10px]">
//           <LoadMoreBtn stories={stories} id={props.data.collection.slug} getMoreStoriesFunction={getSectionStories} />
//         </div>
//       </div>
//     </div>
//   );
// };

// export { SectionPage };

import React, { useEffect, useState } from "react";
import LoadMoreBtn from "../../loaders/LoadMoreBtn";
import { STORY_FIELDS } from "../../../constants";
import SubscribeBox from "../../templates/SubscribeBox";
import { RightImageCard } from "../../skeleton/MobileSkeleton";
import axios from "axios";

const SectionPage = (props) => {
  const [stories, setStories] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  // Function to fetch section stories
  const getSectionStories = async (sectionId, offset) => {
    try {
      // Handle news-brief special case
      const slug = sectionId === "news-brief" ? "news-headlines" : sectionId;

      // Determine if we're fetching from collection or section
      const isCollection = Object.keys(props.data.collection || {}).length > 0;

      let url = isCollection
        ? `/api/v1/collections/${slug}?offset=${offset}&limit=20`
        : `/api/v1/stories?section-id=${sectionId}&offset=${offset}&limit=20&fields=${STORY_FIELDS}`;

      const response = await axios.get(url, {
        headers: { "Content-Type": "application/json" },
      });

      const data = response.data;

      return {
        items: isCollection ? data.items : data.stories,
      };
    } catch (error) {
      console.error(`Error fetching stories:`, error.message);
      return { items: [] };
    }
  };

  useEffect(() => {
    const initializeStories = () => {
      const isCollection = Object.keys(props.data.collection || {}).length > 0;
      const initialStories = isCollection ? props.data.collection.items : props.data.stories;

      setStories(initialStories || []);
      setIsLoading(false);
    };

    initializeStories();
  }, [props.data]);

  if (isLoading) {
    return (
      <div className="mb-2 pb-3">
        <RightImageCard />
      </div>
    );
  }

  const displayName = props.data.collection?.name || props.data.section?.name;
  const displayId = props.data.collection?.slug || props.data.section?.id;

  return (
    <div>
      <div className="mobile:hidden">
        <div className="mx-auto max-w-[950px]">
          <h1 className="gap-6 text-[60px] text-primary">{displayName}</h1>

          <div style={{ display: "flex", gap: "10px", marginTop: "10px" }}>
            <div className="w-[67%]">
              <LoadMoreBtn
                stories={stories}
                id={displayId}
                getMoreStoriesFunction={getSectionStories}
                isSection={true}
                isBookmark={false}
              />
            </div>
            <div className="w-[33%]">
              <SubscribeBox />
            </div>
          </div>
        </div>
      </div>

      {/* Mobile View */}
      <div className="hidden flex-col gap-8 mobile:flex">
        <div className="mr-[10px]">
          <LoadMoreBtn stories={stories} id={displayId} getMoreStoriesFunction={getSectionStories} />
        </div>
      </div>
    </div>
  );
};

export { SectionPage };
