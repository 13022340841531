import React from "react";
import LoadMoreBtn from "../../loaders/LoadMoreBtn";
import { STORY_FIELDS } from "../../../constants";
import SubscribeBox from "../../templates/SubscribeBox";

const AuthorPage = (props) => {
  const author = props.data.author;
  const authorCollection = props.data.authorCollection.items;

  const getAuthorStories = async (authorId, offset) => {
    try {
      let url = `/api/v1/authors/${authorId}/collection?offset=${offset}&limit=40&fields=${STORY_FIELDS}`;
      const res = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await res.json();
      return {
        items: data.items,
      };
    } catch (error) {
      console.error(`error.message`, error.message);
    }
  };

  return (
    <>
      <div className=" mx-auto my-0 max-w-[950px]">
        <div className=" flex flex-col gap-6">
          <div className=" gap flex flex-col pt-[30px] mobile:pt-[15px]">
            <h1 className=" font-inter text-[42px] font-[600] capitalize text-primary mobile:text-[28px]">
              {author["name"]}
            </h1>
            {author["name"] === "Swarajya Staff" ? (
              <></>
            ) : (
              <p className="text-md mobile:font-sm font-satoshi font-[400] leading-[140%]">
                {author["bio"] ? `${author["bio"].replace(/<[^>]*>/g, "")}` : <></>}
              </p>
            )}
          </div>
          {/* Story collection coloum */}
          <div className=" flex items-start gap-3 tablet:justify-center">
            <div className=" flex w-[70%] flex-col gap-[24px] tablet:w-[100%]">
              <LoadMoreBtn stories={authorCollection} id={author.id} getMoreStoriesFunction={getAuthorStories} />
            </div>
            <div className="w-[30%] tablet:hidden">
              <SubscribeBox />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export { AuthorPage };
