// import React, { useEffect, useState } from "react";
// import { groupBy } from "lodash/collection";
// import { Link, ResponsiveImage, LazyLoadImages } from "@quintype/components";
// import { Loading } from "./loading";
// const axios = require("axios");
// const ResponsiveImageWithRatio = ({ src, alt, width, height, isMonthlyMagazine }) => {
//   return (
//     <div
//       className={`${
//         isMonthlyMagazine
//           ? "group relative row-span-2 h-full w-full overflow-hidden rounded"
//           : "relative h-full w-full overflow-hidden rounded"
//       }`}
//     >
//       <img
//         // className="mx-auto h-auto w-full"
//         src={`https://swarajya.gumlet.io/${src}?w=300&q=25&compress=true&format=auto`}
//         alt={alt || "Swarajya Magazine Cover Image"}
//         style={{
//           // aspectRatio: 2 / 3,
//           width: width ? width : "100%",
//           height: height ? height : "100%",
//           objectFit: "cover",
//         }}
//         fill={false}
//         width={"100"}
//         height={"100"}
//       />
//       {isMonthlyMagazine && (
//         <div className="absolute inset-0 flex items-end justify-end p-3">
//           <p className="-mb-[2px] -me-[2px] flex h-[50px] w-[50px] items-center justify-center gap-1 rounded-full bg-primary px-4 py-4 text-center text-[14px] font-semibold text-white">
//             Print
//           </p>
//         </div>
//       )}
//     </div>
//   );
// };
// const IssueItems = ({ item }) => {
//   let cover_image = item.metadata["cover-image"]["cover-image-s3-key"];
//   let isMonthlyMagazine =
//     item.metadata.tags.some((tag) => tag.name === "monthly") &&
//     !item.metadata.tags.some((tag) => tag.name === "Weekly");
//   if (cover_image === null) {
//     let coverArr = item.metadata["cover-image"]["url"].split("/");
//     cover_image = coverArr.slice(3, coverArr.length).join("/");
//   }
//   cover_image = encodeURI(cover_image);
//   return (
//     <div className="flex flex-col">
//       <figure className="flex justify-center">
//         <Link href={`/issue/${item.slug}`} className="flex w-[70%] justify-center mobile:w-[60%]">
//           <ResponsiveImageWithRatio
//             src={cover_image}
//             alt={item.summary}
//             height={"100%"}
//             width={"100%"}
//             isMonthlyMagazine={isMonthlyMagazine}
//           />
//         </Link>
//       </figure>
//       <div className="mt-4 flex flex-col items-center justify-center">
//         <span className="text-center text-base font-[600] mobile:text-sm">{item.name}</span>
//         <Link href={`/issue/${item.slug}`} className="text-sm text-primary">
//           Read Now
//         </Link>
//       </div>
//     </div>
//   );
// };

// const getIssueByFullYear = (issue) => {
//   return new Date(issue["collection-date"] !== null ? +issue["collection-date"] : +issue["created-at"]).getFullYear();
// };

// const Magazine = (props) => {
//   const [issues, setIssues] = useState([]);
//   // async function getAllMagazine() {
//   //   try {
//   //     const response = await axios.get(`https://content.swarajyamag.com/api/magazine?limit=100`);

//   //     return response.data;
//   //   } catch (error) {
//   //     console.error(`Error fetching data for issueSlug ${issueSlug}:`, error);
//   //     throw error;
//   //   }
//   // }
//   // useEffect(() => {
//   //   const fetchMagazines = async () => {
//   //     try {
//   //       const response = await getAllMagazine();
//   //       setIssues(response);
//   //     } catch (error) {
//   //       console.error("Error fetching magazines:", error);
//   //     }
//   //   };

//   //   fetchMagazines();
//   // }, []);

//   // if (issues.length === 0) {
//   //   return (
//   //     <div>
//   //       <Loading />
//   //     </div>
//   //   );
//   // }
//   const all_issues = props.data.issues;
//   let grouped = groupBy(all_issues["story-collections"], (issue) => getIssueByFullYear(issue));

//   let sorted_year = Object.keys(grouped).reverse();

//   return (
//     <div className=" flex flex-col">
//       {/* <h1 className=" text-2xl font-[600]">All Issues</h1> */}
//       <div className="flex flex-col gap-4">
//         {sorted_year.map((year) => {
//           return (
//             <div key={year}>
//               <h2 className="p-5 text-center text-xl font-[600]">{`Year ${year}`}</h2>
//               {/* <IssuedItems /> */}
//               <div className="grid grid-cols-3 gap-4 tablet:grid-cols-2 tablet:mobile:grid-cols-1">
//                 {grouped[year].map((item) => (
//                   <IssueItems item={item} key={item.id} />
//                 ))}
//               </div>
//             </div>
//           );
//         })}
//       </div>
//     </div>
//   );
// };

// export { Magazine };

// api call to get all magazines in frontend (for cache issues fix)

import React, { useEffect, useState } from "react";
import { groupBy } from "lodash";
import { Link } from "@quintype/components";
import { Loading } from "./loading";
import { LoadMoreIcon } from "../../Icons/Icon";

const ResponsiveImageWithRatio = ({ src, alt, width, height, isMonthlyMagazine }) => {
  return (
    <div
      className={`${
        isMonthlyMagazine
          ? "group relative row-span-2 h-full w-full overflow-hidden rounded"
          : "relative h-full w-full overflow-hidden rounded"
      }`}
    >
      <img
        src={`https://swarajya.gumlet.io/${src}?w=300&q=25&compress=true&format=auto`}
        alt={alt || "Swarajya Magazine Cover Image"}
        style={{
          width: width ? width : "100%",
          height: height ? height : "100%",
          objectFit: "cover",
        }}
        width="100"
        height="100"
      />
      {isMonthlyMagazine && (
        <div className="absolute inset-0 flex items-end justify-end p-3">
          <p className="-mb-[2px] -me-[2px] flex h-[50px] w-[50px] items-center justify-center gap-1 rounded-full bg-primary px-4 py-4 text-center text-[14px] font-semibold text-white">
            Print
          </p>
        </div>
      )}
    </div>
  );
};

const IssueItems = ({ item }) => {
  const cover_image = (() => {
    let image = item.metadata["cover-image"]["cover-image-s3-key"];
    if (!image) {
      const coverArr = item.metadata["cover-image"]["url"].split("/");
      image = coverArr.slice(3).join("/");
    }
    return encodeURI(image);
  })();

  const isMonthlyMagazine =
    item.metadata.tags.some((tag) => tag.name === "monthly") &&
    !item.metadata.tags.some((tag) => tag.name === "Weekly");

  return (
    <div className="flex flex-col">
      <figure className="flex justify-center">
        <Link href={`/issue/${item.slug}`} className="flex w-[70%] justify-center mobile:w-[60%]">
          <ResponsiveImageWithRatio
            src={cover_image}
            alt={item.summary}
            height="100%"
            width="100%"
            isMonthlyMagazine={isMonthlyMagazine}
          />
        </Link>
      </figure>
      <div className="mt-4 flex flex-col items-center justify-center">
        <span className="text-center text-base font-[600] mobile:text-sm">{item.name}</span>
        <Link href={`/issue/${item.slug}`} className="text-sm text-primary">
          Read Now
        </Link>
      </div>
    </div>
  );
};

const getIssueByFullYear = (issue) => {
  const date = issue["collection-date"] || issue["created-at"];
  return new Date(date).getFullYear();
};

const Magazine = () => {
  const [issues, setIssues] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const ITEMS_PER_PAGE = 9;

  const fetchMagazines = async (pageNum) => {
    try {
      const offset = (pageNum - 1) * ITEMS_PER_PAGE;
      const response = await fetch(
        `/api/v1/story-collections/content?tag=monthly&limit=${ITEMS_PER_PAGE}&offset=${offset}`
      );

      if (!response.ok) {
        throw new Error("Failed to fetch magazines");
      }

      const data = await response.json();
      return data["story-collections"];
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    const loadInitialData = async () => {
      try {
        const initialIssues = await fetchMagazines(1);
        setIssues(initialIssues);
        setHasMore(initialIssues.length === ITEMS_PER_PAGE);
      } catch (error) {
        console.error("Error fetching magazines:", error);
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    loadInitialData();
  }, []);

  const loadMore = async () => {
    if (loadingMore) return;

    setLoadingMore(true);
    try {
      const nextPage = page + 1;
      const newIssues = await fetchMagazines(nextPage);

      if (newIssues.length > 0) {
        setIssues((prevIssues) => [...prevIssues, ...newIssues]);
        setPage(nextPage);
        setHasMore(newIssues.length === ITEMS_PER_PAGE);
      } else {
        setHasMore(false);
      }
    } catch (error) {
      console.error("Error loading more magazines:", error);
      setError(error.message);
    } finally {
      setLoadingMore(false);
    }
  };

  if (isLoading) {
    return <Loading />;
  }

  if (error) {
    return <div className="text-center text-red-500">Error: {error}</div>;
  }

  const grouped = groupBy(issues, getIssueByFullYear);
  const sortedYears = Object.keys(grouped).sort((a, b) => b - a);

  return (
    <div className="flex flex-col">
      <div className="flex flex-col gap-4">
        {sortedYears.map((year) => (
          <div key={year}>
            <h2 className="p-5 text-center text-xl font-[600]">{`Year ${year}`}</h2>
            <div className="grid grid-cols-3 gap-4 tablet:grid-cols-2 tablet:mobile:grid-cols-1">
              {grouped[year].map((item) => (
                <IssueItems item={item} key={item.id} />
              ))}
            </div>
          </div>
        ))}
      </div>

      {hasMore && (
        <div className="mt-8 flex justify-center">
          <button
            onClick={loadMore}
            disabled={loadingMore}
            className="flex items-center gap-1 rounded px-4 py-2 text-primary shadow-md"
          >
            {/* {loadingMore ? "Loading..." : "Load More"} */}
            Load More
            <span className={`${loadingMore ? "animate-spin" : ""}`}>
              <LoadMoreIcon />
            </span>
          </button>
        </div>
      )}
    </div>
  );
};

export { Magazine };
