import React from "react";
import { StoryCardSkeleton } from "../../skeleton/DesktopSkelton";

export const Loading = () => {
  return (
    <div className="flex flex-col  items-center justify-start gap-10 tablet:justify-center">
      <div className="mt-5 flex w-full items-center"></div>
      <div className=" grid w-full grid-cols-3 gap-4 tablet:grid-cols-2 tablet:mobile:grid-cols-1">
        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 0].map((i) => (
          <StoryCardSkeleton key={i} />
        ))}
      </div>
    </div>
  );
};
