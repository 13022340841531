import React from "react";

import { useEffect, useState } from "react";
import Loading from "../pages/bookmark/loading";
import LoadMoreBtn from "../loaders/LoadMoreBtn";
import { useSelector, useDispatch } from "react-redux";
import get from "lodash/get";
import { BOOKMARK_DATA } from "../../../isomorphic/components/store/actions";
import { getBookmarks } from "../../../api/utils";
const BookmarkTemplates = () => {
  const [filter_bookmarks, setFilteredBookmarks] = useState([]);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  const user = useSelector((state) => get(state, ["firebaseUser"], null));

  // if (bookmarksData !== null) {
  //   let bookmark_filtered_by_article = bookmarksData[0].bookmarks.filter((item) => item.type === "article");
  //   console.log("bookmark_filtered_by_article", bookmark_filtered_by_article);
  //   setFilteredBookmarks(bookmark_filtered_by_article);
  //   setLoading(false);
  // }
  useEffect(() => {
    const getData = async () => {
      if (!user) return;

      const bookmarks = await getBookmarks(user?.email, 0);

      let bookmark_filtered_by_article = bookmarks.filter((item) => item.type === "article");

      setFilteredBookmarks(bookmark_filtered_by_article);
      setLoading(false);
    };
    getData();
  }, [user]);

  //   const bookmarkTabs = [
  //     {
  //       name: "Article",
  //       value: "article",
  //     },
  //     {
  //       name: "Audio Book",
  //       value: "audio-book",
  //     },
  //   ];
  //   const [active, setActive] = useState("article");
  return (
    <>
      {loading ? (
        <div className="mx-auto w-[80%]">
          <Loading />
        </div>
      ) : (
        <>
          {filter_bookmarks.length === 0 ? (
            <div className="mx-auto flex h-[20vh] w-[80%] items-center justify-center">
              <h1 className="text-center text-2xl font-bold">No bookmarks</h1>
            </div>
          ) : (
            <div className="mx-auto w-[70%]">
              <div className="flex flex-col gap-4">
                {/* <Tabs listStates={bookmarkTabs} active={active} setActive={setActive} /> */}
                <h1 className=" font-inter text-[42px] font-[600] capitalize text-primary mobile:text-[28px]">
                  Bookmarks
                </h1>
                {/* {filter_bookmarks?.map((bookmark) => (
                  <BookmarkStoryCards key={bookmark.id} story={bookmark} />
                ))} */}
                <LoadMoreBtn
                  stories={filter_bookmarks}
                  id={user?.email}
                  getMoreStoriesFunction={getBookmarks}
                  isBookmark={true}
                />
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default BookmarkTemplates;
