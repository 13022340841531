import React from "react";

const Loading = () => {
  return (
    <div className=" mx-auto my-0 max-w-[950px]">
      <div className=" flex flex-col gap-4">
        {/* <div className="h-[35px] w-[40%] animate-pulse rounded bg-slate-300"></div> */}
        <div className="h-3 w-[50%] animate-pulse rounded bg-slate-300"></div>
      </div>
      <div className="mt-4 flex flex-col gap-8">
        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 0].map((i) => (
          <div className="flex animate-pulse gap-2 pb-3" key={i}>
            <div className="flex w-full flex-col gap-2 mobile:gap-1">
              <div className="grid grid-cols-3 gap-4">
                <div className="col-span-2 h-2 rounded bg-slate-300"></div>
                <div className="col-span-1 h-2 rounded bg-slate-300"></div>
              </div>
              <div className="mt-2 h-2 rounded bg-slate-300"></div>
              <div className="mt-2 h-2 rounded bg-slate-300"></div>
              <div className="mt-2 h-2 w-[100px] rounded bg-slate-300"></div>
            </div>
            <div className="aspect-video w-[35%] animate-pulse rounded bg-slate-300"></div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Loading;
