import React from "react";
import LoadMoreBtn from "../../loaders/LoadMoreBtn";
import { STORY_FIELDS } from "../../../constants";
import SubscribeBox from "../../templates/SubscribeBox";
import SpecificSection from "../../sections/SpecificSection";
import FeaturedStory from "../../mobile-components/FeaturedStory";
import FeaturedStoryCard from "../../mobile-components/FeaturedStoryCard";

const Headlines = (props) => {
  const stories = props.data.stories;
  let bottomSide = stories.slice(0, 5);

  const getSectionStories = async (id, offset) => {
    try {
      let url = `/api/v1/collections/headlines?offset=${offset}&limit=40&fields=${STORY_FIELDS}`;
      const res = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await res.json();
      return {
        items: data.items,
      };
    } catch (error) {
      console.error(`error.message`, error.message);
    }
  };

  return (
    <>
      <div className="mobile:hidden">
        <div className="mx-auto  max-w-[950px] ">
          <h1 className={` gap-6 text-[60px] text-primary`}>Headlines</h1>
          <SpecificSection stories={bottomSide} />
          <div style={{ display: "flex ", gap: "10px", marginTop: "10px" }}>
            <div className="w-[67%]">
              <LoadMoreBtn
                stories={stories.slice(6)}
                id={props.data.collection.id}
                getMoreStoriesFunction={getSectionStories}
                isSection={true}
                isBookmark={false}
              />
            </div>
            <div className="w-[33%]">
              <SubscribeBox />
            </div>
          </div>
        </div>
      </div>
      {/* Mobile View */}
      <div className="hidden flex-col gap-8 mobile:flex">
        <div>
          {stories.slice(0, 6).map((story, i) => {
            if (i == 0) {
              return <FeaturedStory story={story} key={story.id} />;
            } else {
              return <FeaturedStoryCard story={story} key={story.id} />;
            }
          })}
        </div>

        <SubscribeBox />
        {stories &&
          stories.slice(6, 20).map((story, i) => {
            return <FeaturedStoryCard story={story} key={story.id} />;
          })}

        <div className="mr-[10px]">
          <LoadMoreBtn stories={stories} id={props.data.collection.id} getMoreStoriesFunction={getSectionStories} />
        </div>
      </div>
    </>
  );
};

export { Headlines };
