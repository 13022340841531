// import React from "react";
// import { useEffect, useState } from "react";
// import Loading from "./loading";
// import LoadMoreBtn from "../../loaders/LoadMoreBtn";
// import { useSelector } from "react-redux";
// import get from "lodash/get";
// import { BOOKMARK_DATA } from "../../../../isomorphic/components/store/actions";
// import { getBookmarks } from "../../../../api/utils";

import React from "react";
import BookmarkTemplates from "../../templates/BookmarkTemplates";
const Bookmarks = () => {
  return <BookmarkTemplates />;
};

export { Bookmarks };
