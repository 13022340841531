import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import get from "lodash/get";
import { Link } from "@quintype/components";
import "./profile-page.m.css";
import { checkAuthStatus } from "../../../../server/helpers/firebase";

const ProfilePage = () => {
  const dispatch = useDispatch();
  checkAuthStatus(dispatch);
  const modalRef = useRef();
  const user = useSelector((state) => get(state, ["firebaseUser"], null));
  const [subscriberData, setsubscriberData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [subId, setSubId] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [result, setResult] = useState({
    success: "",
    error: "",
  });
  const [subscriber, setSubscriber] = useState({
    plan_name: "",
    subscriber_email: "",
    metadata: {
      subscriber_name: "",
      email: "",
      address_line_1: "",
      address_line_2: "",
      address_line_3: "",
      address_line_4: "",
      phone: "",
    },
  });
  const [showAddressForm, setShowAddressForm] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setShowModal(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setShowModal]);

  const handleSave = async (e) => {
    e.preventDefault();
    try {
      if (subscriber.plan_name.toLowerCase().includes("team")) {
        let requestBody = {
          Identity: subscriber.subscriber_email,
          name: subscriber.metadata.subscriber_name,
          metadata: {
            phone: subscriber.metadata.phone,
          },
        };
        const response = await fetch(`https://subsapi.swarajyamag.com/api/subscriber?email=${user.email}`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        });
        const data = await response.json();
        if (data.data) {
          getSubscription(user?.email);
        }
        setResult({ ...result, success: "Profile Updated Successfully." });
      } else {
        let requestBody = {
          data: {
            email: subscriber.subscriber_email,
            subscriber_name: subscriber.metadata.subscriber_name,
            phone: subscriber.metadata.phone,
            address_line_1: subscriber.metadata.address_line_1,
            address_line_2: subscriber.metadata.address_line_2,
            address_line_3: subscriber.metadata.address_line_3,
            address_line_4: subscriber.metadata.address_line_4,
          },
        };
        const response = await fetch(`https://subsapi.swarajyamag.com/api/subscriptions/${subId}`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        });
        const data = await response.json();
        if (data.data) {
          getSubscription(user?.email);
        }
        setResult({ ...result, success: "Profile Updated Successfully." });
      }
    } catch (e) {
      setResult({
        ...result,
        error: "Error In Updating Profile Details. Please Try Again.",
      });
      setShowModal(false);
    }
    setTimeout(() => {
      setShowModal(false);
      setResult({ ...result, success: "", error: "" });
    }, 2000);
  };
  const handleEditSubscription = (data) => {
    setShowAddressForm(false);
    const {
      id,
      group_name,
      phone,
      subscriber_name,
      address_line_1,
      address_line_2,
      address_line_3,
      address_line_4,
      email,
      plan_name,
    } = data;
    setSubId(id);
    setSubscriber({
      subscriber_email: email,
      plan_name,
      metadata: {
        subscriber_name: subscriber_name || subscriberData.subscriber_name || "",
        email,
        address_line_1,
        address_line_2,
        address_line_3,
        address_line_4,
        phone: phone || (subscriberData.subscriber_metadata && subscriberData.subscriber_metadata.phone) || "",
      },
    });

    if (["Print", "Recurring", "Heritage", "Abroad", "Patron"].includes(group_name)) {
      setShowAddressForm(true);
    }
  };
  const getSubscription = async (email) => {
    try {
      setLoading(true);
      setSubscriber({ ...subscriber, email: email });
      if (!email) return;
      const res = await fetch(`https://subsapi.swarajyamag.com/api/subscriptions?email=${email}`);
      const data = await res.json();
      if (data.status !== 404) {
        setsubscriberData(data);
        setLoading(false);
      } else {
        throw new Error("No Subscription Found");
      }
    } catch (error) {
      console.error(`Error while fetching  getSubscription  : ${error.message}`);
      setsubscriberData([]);
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    user && getSubscription(user.email);
  }, [user]);

  const RenderLoadingSkelton = () => {
    return (
      <div className="animate-pulse space-y-2 pb-3">
        <div className="flex h-[14px] justify-between">
          <div className="w-[50%] rounded bg-gray-300"></div>
          <div className="w-[40%]"></div>
        </div>

        <div className="rounded">
          <div className="h-[120px] w-[100%] bg-gray-300"></div>
        </div>

        <div className="mt-5 flex h-[14px] justify-between">
          <div className="40%"></div>
          <div className="w-[50%] rounded bg-gray-300"></div>
        </div>
      </div>
    );
  };

  const RenderSubscriptionList = () => {
    return (
      <div>
        <div className={`relative mx-auto w-[100%]`}>
          <div>
            <div className="mb-4 font-medium text-sm">
              Subscription Details For <span className="italic text-primary">{user?.email}</span>
            </div>
            <div>
              <div className="shadow-default font-medium text-sm sm:px-7.5 rounded-sm border border-[#E2E8F0] bg-white px-5 pb-2.5 pt-6 dark:border-[#2E3A47] dark:bg-zinc-800 xl:pb-1">
                <div className="overflow-x-auto">
                  <table className={`table-auto`}>
                    <thead>
                      <tr className="bg-[#F7F9FC] text-left dark:bg-zinc-700">
                        <th className="px-2 py-4 font-semibold text-black dark:text-white">Subscription</th>
                        <th className="px-2 py-4 font-semibold text-black dark:text-white">Start Date</th>
                        <th className="px-2 py-4 font-semibold text-black dark:text-white">End Date</th>
                        <th className="px-2 py-4 font-semibold text-black dark:text-white">Status</th>
                        <th className="px-2 py-4 font-semibold text-black dark:text-white">Mailing Address</th>
                      </tr>
                    </thead>
                    <tbody>
                      {subscriberData?.length === 0 && (
                        <tr>
                          <td colSpan={5} className="px-2 py-4 text-center font-semibold text-black dark:text-white">
                            No subscription found
                          </td>
                        </tr>
                      )}
                      {subscriberData?.subscriptions?.length > 0 &&
                        subscriberData?.subscriptions?.map((sub, index) => {
                          const startDate = new Date(sub.start_timestamp);
                          const endDate = new Date(sub.end_timestamp);
                          return (
                            <React.Fragment key={index}>
                              <tr className="border-b border-[#eee] text-sm hover:bg-[#F7F9FC] dark:border-[#2E3A47] dark:text-[#f1f1f196] hover:dark:bg-zinc-700">
                                <td className="border-b border-[#eee] px-2 py-3 dark:border-[#2E3A47]">
                                  {sub.plan_name.toLowerCase().includes("team")
                                    ? `${sub.plan_name}`
                                    : `${sub.group_name} - ${sub.plan_name}`}
                                </td>
                                <td className="border-b border-[#eee] px-2 py-3 dark:border-[#2E3A47]">
                                  {startDate.toLocaleDateString()}
                                </td>
                                <td className="border-b border-[#eee] px-2 py-3 dark:border-[#2E3A47]">
                                  {endDate.toLocaleDateString()}
                                </td>
                                <td className="border-b border-[#eee] px-2 py-3 dark:border-[#2E3A47]">{sub.status}</td>
                                {sub.plan_name.toLowerCase().includes("team") ? (
                                  <td className="border-b border-[#eee] px-2 py-3 text-center dark:border-[#2E3A47]">
                                    <button
                                      type="button"
                                      className="inline-flex items-center gap-x-2 border-transparent bg-primary px-5 py-2 text-sm font-semibold text-white disabled:pointer-events-none disabled:opacity-50 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                                      onClick={() => {
                                        if (sub.primary_email === user.email) {
                                          window.location.replace(`/team`);
                                        } else {
                                          handleEditSubscription(sub);
                                          setShowModal(true);
                                        }
                                      }}
                                    >
                                      {sub.primary_email === user.email ? "View" : "Edit"}
                                    </button>
                                  </td>
                                ) : (
                                  <td className="border-b border-[#eee] px-2 py-3 text-center dark:border-[#2E3A47]">
                                    <button
                                      type="button"
                                      className="inline-flex items-center gap-x-2 border-transparent bg-primary px-5 py-2 text-sm font-semibold text-white disabled:pointer-events-none disabled:opacity-50 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                                      onClick={() => {
                                        handleEditSubscription(sub);
                                        setShowModal(true);
                                      }}
                                    >
                                      Edit
                                    </button>
                                  </td>
                                )}
                              </tr>
                            </React.Fragment>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="mt-1 text-sm dark:text-[#f1f1f196]">
                <span>For enquiries please write to</span>{" "}
                <span className="text-primary">
                  <a href="mailto:subscriptions@swarajyamag.com">subscriptions@swarajyamag.com</a>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const RenderSignUpRedirection = () => {
    return (
      <div className="flex flex-col items-center justify-center text-primary">
        <h3>Sign in to access profile page.</h3>
        <Link href="/signin" className={`mt-4 rounded-full bg-primary px-8 py-2 text-white`}>
          Sign In
        </Link>
      </div>
    );
  };

  const AddressForm = () => {
    return (
      <>
        <input
          type="text"
          name="address_1"
          className="block w-full rounded-lg border-transparent bg-gray-100 px-4 py-3 ps-11 text-sm  focus:border-blue-500 focus:ring-blue-500 dark:border-transparent dark:bg-gray-700 dark:text-gray-400 dark:focus:ring-gray-600"
          value={subscriber.metadata.address_line_1}
          placeholder="Door Number, Street Number"
          onChange={(e) => {
            e.persist();
            setSubscriber({
              ...subscriber,
              metadata: {
                ...subscriber.metadata,
                address_line_1: e.target.value,
              },
            });
          }}
        />

        <input
          type="text"
          name="address_2"
          className="peer block w-full rounded-lg border-transparent bg-gray-100 px-4 py-3 ps-11 text-sm focus:border-blue-500 focus:ring-blue-500 disabled:pointer-events-none disabled:opacity-50 dark:border-transparent dark:bg-gray-700 dark:text-gray-400 dark:focus:ring-gray-600"
          value={subscriber.metadata.address_line_2}
          placeholder="Area/Locality, With Nearest Landmark Details"
          onChange={(e) => {
            e.persist();
            setSubscriber({
              ...subscriber,
              metadata: {
                ...subscriber.metadata,
                address_line_2: e.target.value,
              },
            });
          }}
        />

        <input
          type="text"
          name="address_3"
          className="peer block w-full rounded-lg border-transparent bg-gray-100 px-4 py-3 ps-11 text-sm focus:border-blue-500 focus:ring-blue-500 disabled:pointer-events-none disabled:opacity-50 dark:border-transparent dark:bg-gray-700 dark:text-gray-400 dark:focus:ring-gray-600"
          value={subscriber.metadata.address_line_3}
          placeholder="City and State"
          onChange={(e) => {
            e.persist();
            setSubscriber((prevSubscriber) => {
              return {
                ...prevSubscriber,
                metadata: {
                  ...prevSubscriber.metadata,
                  address_line_3: e.target.value,
                },
              };
            });
          }}
        />

        <input
          type="text"
          name="address_4"
          className="peer block w-full rounded-lg border-transparent bg-gray-100 px-4 py-3 ps-11 text-sm focus:border-blue-500 focus:ring-blue-500 disabled:pointer-events-none disabled:opacity-50 dark:border-transparent dark:bg-gray-700 dark:text-gray-400 dark:focus:ring-gray-600"
          value={subscriber.metadata.address_line_4}
          placeholder="Pincode"
          onChange={(e) => {
            e.persist();
            setSubscriber({
              ...subscriber,
              metadata: {
                ...subscriber.metadata,
                address_line_4: e.target.value,
              },
            });
          }}
        />
      </>
    );
  };
  const RenderModalForm = () => {
    return (
      <>
        <div
          // id="hs-basic-modal"
          // className="hs-overlay fixed start-0 top-0 z-[80] hidden h-full w-full overflow-y-auto overflow-x-hidden"
          className={`fixed inset-0 z-50 flex items-center justify-center overflow-y-auto overflow-x-hidden outline-none transition-all duration-500 ease-out focus:outline-none tablet:mx-auto tablet:block tablet:w-[80%]`}
        >
          <div className={`mt-5 transition-[opacity] duration-500 ease-out sm:mx-auto sm:w-full sm:max-w-lg`}>
            <div ref={modalRef} className="relative flex flex-col rounded-xl bg-white shadow-lg dark:bg-gray-800">
              <div className="absolute end-2 top-2 mt-3">
                <button
                  type="button"
                  className="flex h-7 w-7 items-center justify-center rounded-lg border border-transparent text-sm font-semibold text-gray-800 hover:bg-gray-100 disabled:pointer-events-none disabled:opacity-50 dark:border-transparent dark:text-white dark:hover:bg-gray-700 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                  data-hs-overlay="#hs-basic-modal"
                  onClick={() => setShowModal(false)}
                >
                  <span className="sr-only">Close</span>
                  <svg
                    className="h-4 w-4 flex-shrink-0"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="M18 6 6 18" />
                    <path d="m6 6 12 12" />
                  </svg>
                </button>
              </div>
              <div className="overflow-y-auto p-4 text-center sm:p-10">
                <h3 className="mb-4 text-2xl font-bold text-gray-800 dark:text-gray-200">Update Details</h3>
                <form className="flex flex-col gap-4">
                  <input
                    type="text"
                    name="name"
                    placeholder="Enter your name"
                    className="peer block w-full rounded-lg border-transparent bg-gray-100 px-4 py-3 ps-11 text-sm focus:border-blue-500 focus:ring-blue-500 disabled:pointer-events-none disabled:opacity-50 dark:border-transparent dark:bg-gray-700 dark:text-gray-400 dark:focus:ring-gray-600"
                    value={subscriber.metadata.subscriber_name}
                    onChange={(e) => {
                      e.persist();
                      setSubscriber((prevSubscriber) => ({
                        ...prevSubscriber,
                        metadata: {
                          ...prevSubscriber.metadata,
                          subscriber_name: e.target.value,
                        },
                      }));
                    }}
                  />
                  <input
                    type="email"
                    name="email"
                    className="peer block w-full rounded-lg border-transparent bg-gray-100 px-4 py-3 ps-11 text-sm focus:border-blue-500 focus:ring-blue-500 disabled:pointer-events-none disabled:opacity-50 dark:border-transparent dark:bg-gray-700 dark:text-gray-400 dark:focus:ring-gray-600"
                    disabled
                    value={user?.email}
                  />
                  <input
                    type="text"
                    name="mobile"
                    placeholder="Enter your mobile number"
                    className="peer block w-full rounded-lg border-transparent bg-gray-100 px-4 py-3 ps-11 text-sm focus:border-blue-500 focus:ring-blue-500 disabled:pointer-events-none disabled:opacity-50 dark:border-transparent dark:bg-gray-700 dark:text-gray-400 dark:focus:ring-gray-600"
                    value={subscriber.metadata.phone}
                    onChange={(e) => {
                      e.persist();
                      setSubscriber((prevSubscriber) => ({
                        ...prevSubscriber,
                        metadata: {
                          ...prevSubscriber.metadata,
                          phone: e.target.value,
                        },
                      }));
                    }}
                  />
                  {showAddressForm ? AddressForm() : null}

                  <div className="mt-3 flex justify-center gap-x-4">
                    <button
                      className="inline-flex items-center gap-x-2 rounded-sm bg-primary px-5 py-2 text-sm text-white shadow-sm hover:bg-[#b33232] dark:focus:outline-none"
                      type="submit"
                      onClick={(e) => {
                        handleSave(e);
                      }}
                    >
                      Save
                    </button>
                    <button
                      type="button"
                      className="inline-flex items-center gap-x-2 rounded-sm border border-gray-200 bg-white px-5 py-2 text-sm text-gray-800 shadow-sm hover:bg-gray-200 dark:focus:outline-none"
                      data-hs-overlay="#hs-basic-modal"
                      onClick={() => setShowModal(false)}
                    >
                      Cancel
                    </button>
                  </div>
                  {result.error ? <p className="text-red-500">{result.error}</p> : null}
                  {result.success ? <p className="text-green-500">{result.success}</p> : null}
                </form>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="max-w-fit mx-auto">
      {showModal ? RenderModalForm() : null}
      {loading ? RenderLoadingSkelton() : user ? RenderSubscriptionList() : RenderSignUpRedirection()}
    </div>
  );
};

export { ProfilePage };
