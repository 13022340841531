import React from "react";
import LoadMoreBtn from "../../loaders/LoadMoreBtn";
import SubscribeBox from "../../templates/SubscribeBox";

const TagPage = (props) => {
  const tagStories = props.data.stories;

  const getTagStories = async (tagSlug, offset) => {
    try {
      let url = `/api/v1/stories?tag=${tagSlug}&offset=${offset}&limit=40&fields=${STORY_FIELDS}`;
      const res = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await res.json();
      return {
        items: data.stories,
      };
    } catch (error) {
      console.error(`error.message`, error.message);
    }
  };

  return (
    <div className="mx-auto my-0 max-w-[950px]">
      <div className=" flex flex-col gap-6">
        <div className=" gap flex flex-col pt-[30px] mobile:pt-[15px]">
          <h1 className=" font-inter text-[42px] font-[600] capitalize text-primary mobile:text-[28px]">
            {decodeURIComponent(props.data.title)}
          </h1>
        </div>
        {/* Story collection coloum */}
        <div className=" flex items-start gap-3 tablet:justify-center">
          <div className=" flex w-[70%] flex-col gap-[24px] tablet:w-[100%]">
            <LoadMoreBtn stories={tagStories} id={props.data.tagSlug} getMoreStoriesFunction={getTagStories} />
          </div>
          <div className="w-[30%] tablet:hidden">
            <SubscribeBox />
          </div>
        </div>
      </div>
    </div>
  );
};
export { TagPage };
