import React from "react";
import { Link } from "@quintype/components";

// const NotFoundPage = () => <div className="container">NotFound</div>;
const NotFoundPage = () => {
  return (
    <div className="bg-transperant grid h-screen place-content-center px-4">
      <div className="text-center">
        <h1 className="text-9xl font-black text-gray-200">404</h1>

        <p className="text-2xl font-bold tracking-tight text-gray-900 dark:text-gray-200 sm:text-4xl">Uh-oh!</p>

        <p className="mt-4 text-gray-500">We cannot find that page.</p>
        <Link
          href={`/`}
          className="mt-6 inline-block rounded bg-primary px-5 py-3 text-sm font-medium text-white hover:bg-primary focus:outline-none focus:ring"
        >
          Go Back Home
        </Link>
      </div>
    </div>
  );
};

export { NotFoundPage };
