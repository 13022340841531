import React from "react";
import { StoryCardSkeleton } from "../../skeleton/DesktopSkelton";

export const Loading = () => {
  return (
    <div className="flex flex-col  items-center justify-start gap-10 tablet:justify-center">
      <div className="mt-5 flex w-full items-center">
        <div className="px-5 py-4">
          <div className="h-[350px] w-[250px] animate-pulse bg-gray-300"></div>
        </div>
        <div className="w-full px-5 py-4">
          <div className="space-y-3">
            <div className="grid grid-cols-3 gap-4">
              <div className="col-span-2 h-2 rounded bg-slate-300"></div>
              <div className="col-span-1 h-2 rounded bg-slate-300"></div>
            </div>
            <div className="h-2 rounded bg-slate-300"></div>
            <div className="h-2 rounded bg-slate-300"></div>
            <div className="grid grid-cols-3 gap-4">
              <div className="col-span-2 h-2 rounded bg-slate-300"></div>
              <div className="col-span-1 h-2 rounded bg-slate-300"></div>
            </div>
            <div className="h-2 rounded bg-slate-300"></div>
            <div className="h-2 w-[100px] rounded bg-slate-300"></div>
          </div>
        </div>
      </div>
      <div className=" grid w-full grid-cols-3 gap-4 tablet:grid-cols-2 tablet:mobile:grid-cols-1">
        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 0].map((i) => (
          <StoryCardSkeleton key={i} />
        ))}
      </div>
    </div>
  );
};
