// import React, { useEffect, useState } from "react";
// import { ResponsiveImage, Link, LazyLoadImages } from "@quintype/components";
// import StoryWithImage from "../../../components/cards/StoryWithImage&Summary";
// import { Loading } from "./loading";
// import { MagazinePrintSubsribe } from "../../../utils/gtm";
// import { ResponsiveBannerImage } from "../../../utils/responsive-image";
// import { get } from "lodash";
// import { useSelector } from "react-redux";
// const RenderMagazineStories = ({ magazineStoriesItem, section }) => {
//   return (
//     <div className="flex flex-col gap-3" key={section.section_slug}>
//       <div>
//         {section.section_slug === "cover" || section.section_slug === "others" ? (
//           <h1 className="text-xl font-[600] capitalize">{section.section_slug}</h1>
//         ) : (
//           <Link href={`/section/${section.section_slug}`}>
//             <h1 className="text-xl font-[600] capitalize">{section.section_slug}</h1>
//           </Link>
//         )}
//         <hr className="mb-2 h-1 w-6 bg-primary" />
//       </div>
//       <div className=" grid grid-cols-3 gap-4 tablet:grid-cols-2 tablet:mobile:grid-cols-1">
//         {magazineStoriesItem.items.map((item, i) => {
//           return (
//             <div className="flex w-full" key={item.id}>
//               <StoryWithImage story={{ story: item }} s3_key={item.hero_image_s3_key} full_width={true} />
//             </div>
//           );
//         })}
//       </div>
//     </div>
//   );
// };

// export function IssuePage(props) {
//   const [stories, setStories] = useState();
//   const getState = useSelector((state) => state);

//   const magMenu = get(getState, ["qt", "data", "navigationMenu", "magMenu"], []);

//   function filterObjectsByTagName(dataArray, tagName) {
//     return dataArray.filter((item) => {
//       if (item.type === "story" && item.story && item.story.tags) {
//         return item.story.tags.some((tag) => tag.name.toLowerCase() === tagName.toLowerCase());
//       }
//       return false;
//     });
//   }

//   if (stories) {
//     let magdata = stories?.items;
//     const filteredData = filterObjectsByTagName(magdata, "cover");
//   }
//   // Usage example:
//   // const filteredData = filterObjectsByTagName(yourDataArray, 'cover');
//   const magazineStoriesItem = props.data.issue;
//   const sections = props.data.sections.data[0].attributes.items;
//   if (!magazineStoriesItem || !sections) return <Loading />;

//   const isMonthlyMagazine = magazineStoriesItem.metadata.tags.some((tag) => tag.name === "monthly");

//   // let coverImage =
//   //   magazineStoriesItem?.metadata?.cover_image?.cover_image_s3_key ||
//   //   magazineStoriesItem?.metadata?.cover_image?.url.split("/").slice(3).join("/");

//   // const filteredBySection = magazineStoriesItem.items.reduce((acc, item) => {
//   //   const item_section = item.magazine_section || item.slug.split("/")[0];
//   //   acc[item_section] = {
//   //     section_name: item_section,
//   //     items: [...(acc[item_section]?.items || []), item],
//   //   };
//   //   return acc;
//   // }, {});

//   let coverImage =
//     magazineStoriesItem?.metadata?.coverimage?.cover_image_s3_key ||
//     magazineStoriesItem?.metadata?.cover_image?.url.split("/").slice(3).join("/");

//   const filteredBySection = magazineStoriesItem.items.reduce((acc, item) => {
//     const item_section = item.magazine_section || item.slug.split("/")[0];
//     acc[item_section] = {
//       section_name: item_section,
//       items: [...(acc[item_section]?.items || []), item],
//     };
//     return acc;
//   }, {});
//   return (
//     <div className="mx-auto  flex max-w-[950px] flex-col gap-8 text-[60px]">
//       <div className="flex items-center justify-start gap-10 tablet:flex-col tablet:justify-center">
//         <Link href={`/issue/${magazineStoriesItem["slug"]}`} className=" w-[30%] shadow-2xl tablet:w-[300px]">
//           <LazyLoadImages>
//             {/* <ResponsiveImage
//               slug={encodeURI(coverImage)}
//               width={300}
//               height={400}
//               alt={magazineStoriesItem["summary"]}
//               ratio={2 / 3}
//               imgParams={{ auto: ["format", "compress"], q: 75, fm: "webp" }}
//               // isMonthlyMagazine={isMonthlyMagazine}
//             /> */}
//             <ResponsiveBannerImage slug={encodeURI(coverImage)} alt={magazineStoriesItem["summary"]} />
//           </LazyLoadImages>
//         </Link>
//         <div className="flex w-[60%] flex-col tablet:w-[90%] tablet:items-center">
//           <h1 className="text-[36px] font-[600] tablet:text-center tablet:text-[28px]">{magazineStoriesItem.name}</h1>
//           <span className=" mb-[10px] text-[20px] font-[300] tablet:text-center tablet:text-base">
//             {magazineStoriesItem.summary}
//           </span>
//           <div className="flex gap-4">
//             <Link
//               className="flex w-[194px] justify-center rounded-sm border border-transparent bg-primary px-3 py-2 font-satoshi text-sm text-white transition-all focus:outline-none focus:ring-offset-2 dark:focus:ring-offset-gray-800 mobile:w-[140px]"
//               href={`https://join.swarajyamag.com/print/?utm_source=website&utm_medium=website&utm_campaign=mag_page`}
//               target="_blank"
//             >
//               <button type="button" aria-label="get_print_sub_btn" onClick={MagazinePrintSubsribe}>
//                 Get Print @ 2400
//               </button>
//             </Link>
//             <Link
//               href={`/all-issues`}
//               className="flex w-[194px] justify-center rounded-sm border border-primary px-3 py-2 font-satoshi text-sm text-primary transition-all focus:outline-none focus:ring-offset-2 dark:focus:ring-offset-gray-800 mobile:w-[140px]"
//             >
//               <button type="button" aria-label="get_print_sub_btn" onClick={MagazinePrintSubsribe}>
//                 Previous Issues
//               </button>
//             </Link>
//           </div>
//         </div>
//       </div>

//       {sections.map((section) => {
//         const filteredSection = filteredBySection[section.section_slug];
//         if (filteredSection) {
//           delete filteredBySection[section.section_slug];
//           return (
//             <RenderMagazineStories key={section.section_slug} section={section} magazineStoriesItem={filteredSection} />
//           );
//         }
//         return null;
//       })}
//       {filteredBySection && Object.keys(filteredBySection).length > 0 && (
//         <RenderMagazineStories
//           key="others"
//           section={{ section_slug: "others" }}
//           magazineStoriesItem={{
//             items: Object.values(filteredBySection).flatMap((section) => section.items),
//           }}
//         />
//       )}
//     </div>
//   );
// }

import React, { useEffect, useState } from "react";
import { ResponsiveImage, Link, LazyLoadImages } from "@quintype/components";
import StoryWithImage from "../../../components/cards/StoryWithImage&Summary";
import { Loading } from "./loading";
import { MagazinePrintSubsribe } from "../../../utils/gtm";
import { ResponsiveBannerImage } from "../../../utils/responsive-image";
import { get } from "lodash";
import { useSelector } from "react-redux";

const RenderMagazineStories = ({ magazineStoriesItem, section, title }) => {
  return (
    <div className="flex flex-col gap-3" key={section["section-slug"]}>
      <div>
        {section["title"] === "Cover" || title === "Others" ? (
          <h1 className="text-xl font-[600] capitalize">{title}</h1>
        ) : (
          <Link href={`/section/${section["section-slug"]}`}>
            <h1 className="text-xl font-[600] capitalize">{title}</h1>
          </Link>
        )}
        <hr className="mb-2 h-1 w-6 bg-primary" />
      </div>
      <div className=" grid grid-cols-3 gap-4 tablet:grid-cols-2 tablet:mobile:grid-cols-1">
        {magazineStoriesItem.items.map((item, i) => {
          return (
            <div className="flex w-full" key={item.id}>
              <StoryWithImage story={{ story: item.story }} s3_key={item["hero-image-s3-key"]} full_width={true} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export function IssuePage(props) {
  const getState = useSelector((state) => state);

  const magazineStoriesItem = props.data.issue;
  const sections = get(getState, ["qt", "data", "navigationMenu", "magMenu"], []);
  if (!magazineStoriesItem || !sections) return <Loading />;

  let coverImage =
    magazineStoriesItem?.metadata?.coverimage?.["cover-image-s3-key"] ||
    magazineStoriesItem?.metadata?.["cover-image"]?.url?.split("/")?.slice(3)?.join("/") ||
    "";

  const filteredBySection = magazineStoriesItem.items.reduce((acc, item) => {
    const storyAttributes = item.story?.metadata?.["story-attributes"];
    const magazineSection = storyAttributes?.magazinesection;

    let itemSection = "Other"; // Default section

    if (Array.isArray(magazineSection) && magazineSection.length > 0) {
      itemSection = magazineSection[0];
    }

    // If the section doesn't exist in acc, create it
    if (!acc[itemSection]) {
      acc[itemSection] = {
        section_name: itemSection,
        items: [],
      };
    }

    // Add the current item to the appropriate section
    acc[itemSection].items.push(item);

    return acc;
  }, {});

  return (
    <div className="mx-auto  flex max-w-[950px] flex-col gap-8 text-[60px]">
      <div className="flex items-center justify-start gap-10 tablet:flex-col tablet:justify-center">
        <Link href={`/issue/${magazineStoriesItem["slug"]}`} className=" w-[30%] shadow-2xl tablet:w-[300px]">
          <LazyLoadImages>
            {/* <ResponsiveImage
              slug={encodeURI(coverImage)}
              width={300}
              height={400}
              alt={magazineStoriesItem["summary"]}
              ratio={2 / 3}
              imgParams={{ auto: ["format", "compress"], q: 75, fm: "webp" }}
              // isMonthlyMagazine={isMonthlyMagazine}
            /> */}
            <ResponsiveBannerImage slug={encodeURI(coverImage)} alt={magazineStoriesItem["summary"]} />
          </LazyLoadImages>
        </Link>
        <div className="flex w-[60%] flex-col tablet:w-[90%] tablet:items-center">
          <h1 className="text-[36px] font-[600] tablet:text-center tablet:text-[28px]">{magazineStoriesItem.name}</h1>
          <span className=" mb-[10px] text-[20px] font-[300] tablet:text-center tablet:text-base">
            {magazineStoriesItem.summary}
          </span>
          <div className="flex gap-4">
            <Link
              className="flex w-[194px] justify-center rounded-sm border border-transparent bg-primary px-3 py-2 font-satoshi text-sm text-white transition-all focus:outline-none focus:ring-offset-2 dark:focus:ring-offset-gray-800 mobile:w-[140px]"
              href={`https://join.swarajyamag.com/print/?utm_source=website&utm_medium=website&utm_campaign=mag_page`}
              target="_blank"
            >
              <button type="button" aria-label="get_print_sub_btn" onClick={MagazinePrintSubsribe}>
                Get Print @ 2400
              </button>
            </Link>
            <Link
              href={`/all-issues`}
              className="flex w-[194px] justify-center rounded-sm border border-primary px-3 py-2 font-satoshi text-sm text-primary transition-all focus:outline-none focus:ring-offset-2 dark:focus:ring-offset-gray-800 mobile:w-[140px]"
            >
              <button type="button" aria-label="get_print_sub_btn" onClick={MagazinePrintSubsribe}>
                Previous Issues
              </button>
            </Link>
          </div>
        </div>
      </div>

      {sections.map((section) => {
        const filteredSection = filteredBySection[section.title];

        if (filteredSection) {
          delete filteredBySection[section.title];
          return (
            <RenderMagazineStories
              key={section.title}
              section={section}
              magazineStoriesItem={filteredSection}
              title={filteredSection.section_name}
            />
          );
        }
        return null;
      })}
      {filteredBySection && Object.keys(filteredBySection).length > 0 && (
        <RenderMagazineStories
          key="others"
          section={{ section_slug: "others" }}
          magazineStoriesItem={{
            items: Object.values(filteredBySection).flatMap((section) => section.items),
          }}
          title="Others"
        />
      )}
    </div>
  );
}
